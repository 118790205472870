









































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

interface CategoryTypes {
  id: string;
  name: string;
}

@Component({
  components: {},
  computed: {}
})
export default class PostCategoryModal extends Vue {
  @Prop(String) readonly selectedCategoryId!: string;
  get categories() {
    return this.$store.getters['posts/CATEGORIES'];
  }
  isEditing = false;
  editedCategory: any = null;
  updateValue = '';
  insertValue = '';
  insertLoading = false;
  updateLoading = false;
  deleteLoading = false;

  selectCategory(item: CategoryTypes) {
    const elem: HTMLElement = this.$refs.updateInput as HTMLElement;
    this.isEditing = true;
    this.editedCategory = item.id;
    this.updateValue = item.name;
    setTimeout(() => {
      elem.focus();
    }, 500);
  }

  cancelEditing() {
    this.isEditing = false;
    this.editedCategory = null;
    this.insertValue = '';
  }

  insertCategory() {
    if (this.insertValue !== '') {
      this.insertLoading = true;
      this.$store
        .dispatch('posts/INSERT_NEW_CATEGORY', {
          name: this.insertValue
        })
        .finally(() => {
          this.insertLoading = false;
          this.insertValue = '';
        });
    }
  }
  deleteCategory(id: string) {
    this.cancelEditing();
    this.$swal({
      title: 'Are you sure want to delete this category?',
      text: 'Every posts using this category would be uncategorized',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.selectedCategoryId !== id) {
          this.deleteLoading = true;
          this.$store.dispatch('posts/DELETE_CATEGORY', id).finally(() => {
            this.deleteLoading = false;
          });
        } else {
          this.$swal(
            'Failed',
            'Category is selected on current article',
            'error'
          );
        }
      }
    });
  }
  updateCategory() {
    if (this.updateValue !== '') {
      this.updateLoading = true;
      setTimeout(() => {
        this.$store
          .dispatch('posts/UPDATE_CATEGORY', {
            id: this.editedCategory,
            name: this.updateValue
          })
          .finally(() => {
            this.updateLoading = false;
          });
      }, 2000);
    }
  }
}
