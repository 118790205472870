var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"mb-10 pt-lg-0 pt-5 text-center"},[_c('img',{staticClass:"mb-8",attrs:{"height":"72","src":"/media/logo/logo-black.png","alt":""}}),_c('h3',{staticClass:"font-weight-bolder text-dark font-size-h3 font-size-h2-lg"},[_vm._v(" Content Management System ")]),_c('span',{staticClass:"text-dark-50 font-weight-bold font-size-h5 font-size-h4-lg"},[_vm._v(" Please sign in to continue ")])]),_c('transition',{attrs:{"name":"component-fade","enter-active-class":"animate__animated animate__faster animate__fadeIn","leave-active-class":"animate__animated animate__faster animate__fadeOut","mode":"out-in"}},[(_vm.failed)?_c('div',{key:1,staticClass:"alert alert-custom alert-notice alert-light-danger fade show mb-7",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" Email address or Password are Incorrect ")])]):(_vm.success)?_c('div',{key:2,staticClass:"alert alert-custom alert-notice alert-light-primary fade show mb-7",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" Welcome, you'll be redirected soon.. ")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" Email address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"email","disabled":_vm.loading,"autocomplete":"off","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"password","disabled":_vm.loading,"autocomplete":"off","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group d-flex justify-content-between align-items-center"},[_c('button',{staticClass:"btn btn-primary btn-block font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3",staticStyle:{"min-width":"120px","min-height":"50px"},attrs:{"id":"kt_login_signin_submit","type":"submit"}},[(!_vm.loading)?_c('div',[_c('span',[_vm._v("Sign In")])]):_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"spinner spinner-track spinner-primary spinner-sm mr-6"}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading...")])])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }