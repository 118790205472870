var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"pb-13 pt-lg-0 pt-5"},[_c('img',{staticClass:"d-none d-lg-block mb-8",attrs:{"height":"72","src":"/media/logo/automate-logo-only.png","alt":""}}),_c('img',{staticClass:"d-block d-md-none mb-8",attrs:{"height":"38","src":"/media/logo/logo-horizontal-dark.png","alt":""}}),_c('h3',{staticClass:"font-weight-bolder text-dark font-size-h2 font-size-h1-lg"},[_vm._v(" Request Account ")]),_c('span',{staticClass:"text-dark-50 font-weight-bold font-size-h5 font-size-h4-lg"},[_vm._v(" Please fill fields below to request an account ")])]),_c('transition',{attrs:{"name":"component-fade","enter-active-class":"animate__animated animate__faster animate__fadeIn","leave-active-class":"animate__animated animate__faster animate__fadeOut","mode":"out-in"}},[(_vm.isFailed)?_c('div',{key:1,staticClass:"alert alert-custom alert-notice alert-light-warning fade show mb-7",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" Failed request account, please check again your inputs ")])]):(_vm.isSuccess)?_c('div',{key:2,staticClass:"alert alert-custom alert-notice alert-light-primary fade show mb-7",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" Thank you for requesting account, our marketing will contact you soon ")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" Email address ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"email","autocomplete":"off","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"PIC Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" PIC Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"text","autocomplete":"off","required":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" Company Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company_name),expression:"company_name"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"text","autocomplete":"off","required":""},domProps:{"value":(_vm.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company_name=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Mobile Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{staticClass:"font-size-h6 font-weight-bolder text-dark"},[_vm._v(" Mobile Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobile_no),expression:"mobile_no"}],staticClass:"form-control form-control-solid h-auto py-6 px-6 rounded-lg",class:classes,attrs:{"type":"number","autocomplete":"off","required":""},domProps:{"value":(_vm.mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mobile_no=$event.target.value}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group d-flex justify-content-between align-items-center"},[_c('a',{staticClass:"text-primary font-size-h6 font-weight-bolder text-hover-primary",attrs:{"id":"kt_login_forgot","href":"javascript:;"},on:{"click":function($event){return _vm.$emit('changePage', 'signin')}}},[_c('i',{staticClass:"fa fa-chevron-left text-primary mr-2"}),_vm._v(" Back ")]),_c('button',{staticClass:"btn btn-danger font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3",attrs:{"id":"kt_login_signin_submit","type":"submit"}},[_c('i',{staticClass:"far fa-paper-plane text-white mr-2"}),_vm._v(" Request Account ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }