












































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import MainConfig from '@/config/config';
import axios from 'axios';

@Component({
  components: {},
  computed: {}
})
export default class ModalAddCategory extends Vue {
  @Prop(String) parent_id: string;
  loading_add = false;
  main_config = MainConfig;
  dataToPost: any = {
    name: '',
    name_en: '',
    parent_id: null
  };

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async add() {
    if (!MainConfig.translation) this.dataToPost.name_en = this.dataToPost.name;
    this.loading_add = true;
    try {
      if (this.parent_id) {
        this.dataToPost.parent_id = this.parent_id;
      }
      await axios.post('files/category/add', this.dataToPost);
      this.dataToPost.name = '';
      this.dataToPost.name_en = '';
      this.loading_add = false;
      await this.$nextTick();
      this.$refs.observer.reset();
      this.$root.$emit('bv::hide::modal', 'modal-add-category');
      this.$emit('refresh');
    } catch (error) {
      this.loading_add = false;
    }
  }
}
