





































import { Component, Vue } from 'vue-property-decorator';
import Headbar from '@/layouts/Headbar.vue';
import Sidebar from '@/layouts/Sidebar.vue';

@Component({
  components: {
    Headbar,
    Sidebar
  }
})
export default class App extends Vue {
  get loginStatus() {
    return this.$store.getters['user/LOGIN_STATUS'];
  }
  get currentMenu() {
    return this.$route.name;
  }
}
