





















































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import ModalAddCategory from '../components/ModalAddCategory.vue';
import ModalUpdateCategory from '../components/ModalUpdateCategory.vue';
import MainConfig from '@/config/config';
import axios from 'axios';

@Component({
  components: { ModalAddCategory, ModalUpdateCategory },
  computed: {}
})
export default class AssetsSubcategory extends Vue {
  limit = 8;
  page = 1;
  loading_data = true;
  loading_add = false;
  loading_update = false;
  loading_delete = false;
  failedFetch = false;
  editMode = false;
  selectedCategory = '';
  data = [];
  total_rows = 0;
  main_config = MainConfig;
  dataToPost = {
    name: '',
    name_en: ''
  };

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async mounted() {
    await this.getData();
  }

  get category_id() {
    return this.$route.params.id;
  }

  async getData() {
    try {
      this.failedFetch = false;
      this.loading_data = true;
      const { data } = await axios.get(
        `/files/subcategory?limit=${this.limit}&page=${this.page}&category_id=${this.category_id}`
      );
      this.data = data.data.data;
      this.total_rows = data.data.pagination.total_rows;
      this.loading_data = false;
    } catch (error) {
      this.loading_data = false;
      this.failedFetch = true;
    }
  }

  onModalHide() {
    this.loading_data = true;
    this.data = [];
    this.page = 1;
    this.total_rows = 0;
    this.dataToPost.name = '';
    this.dataToPost.name_en = '';
    this.editMode = false;
  }

  async add() {
    if (!MainConfig.translation) this.dataToPost.name_en = this.dataToPost.name;
    this.loading_add = true;
    this.page = 1;
    try {
      await axios.post('files/subcategory/add', this.dataToPost);
      this.dataToPost.name = '';
      this.dataToPost.name_en = '';
      this.loading_add = false;
      await this.$nextTick();
      this.$refs.observer.reset();
      this.getData();
    } catch (error) {
      this.loading_add = false;
    }
  }

  deleteCategory(id: string) {
    this.$swal({
      title: 'Are you sure want to delete this category?',
      text: 'Every files using this category would be uncategorized',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          this.loading_delete = true;
          this.page = 1;
          await axios.delete('files/subcategory/delete/' + id);
          this.loading_delete = false;
          this.getData();
        } catch (error) {
          this.loading_delete = false;
        }
      }
    });
  }

  async update() {
    if (!MainConfig.translation) this.dataToPost.name_en = this.dataToPost.name;
    this.loading_update = true;
    try {
      await axios.put(
        'files/subcategory/update/' + this.selectedCategory,
        this.dataToPost
      );
      this.dataToPost.name = '';
      this.dataToPost.name_en = '';
      this.selectedCategory = '';
      this.editMode = false;
      this.loading_update = false;
      this.page = 1;
      await this.$nextTick();
      this.$refs.observer.reset();
      this.getData();
    } catch (error) {
      this.loading_update = false;
    }
  }

  onPageChange(selectedPage: number) {
    this.page = selectedPage;
    this.getData();
  }

  showEditMode(item: any) {
    this.editMode = true;
    this.dataToPost.name = item.name;
    this.dataToPost.name_en = item.name_en;
    this.selectedCategory = item.id;
  }

  cancel() {
    this.editMode = false;
    this.dataToPost.name = '';
    this.dataToPost.name_en = '';
    this.selectedCategory = '';
  }
}
