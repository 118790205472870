import user from './modules/user';
import posts from './modules/posts';
import summary from './modules/summary';
import pages from './modules/pages';
import sections from './modules/sections';
import member from './modules/member';

export default {
  user,
  summary,
  posts,
  pages,
  sections,
  member
};
