































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import 'vue2-datepicker/index.css';
import Editor from '@tinymce/tinymce-vue';
import { ValidationObserver } from 'vee-validate';
import UploadInput from '../UploadInput/UploadInput.vue';
import axios from 'axios';

@Component({
  components: {
    Editor,
    UploadInput
  },
  computed: {}
})
export default class CardsAddModal extends Vue {
  @Prop(Object) selected_section: any;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  doShow = false;
  loading = false;

  dataToPost = {
    title: null,
    subtitle: null,
    description: null,
    description2: null,
    url: null,
    image: null,
    icon: null,
    section_id: null
  };

  get configuration() {
    if (this.selected_section.configuration) {
      return JSON.parse(this.selected_section.configuration);
    } else {
      return null;
    }
  }
  get fields() {
    return this.configuration.fields;
  }

  async submit() {
    this.loading = true;
    this.dataToPost.section_id = this.selected_section.id;
    await axios.post('/cards/add', this.dataToPost);
    this.loading = false;
    this.$root.$emit('bv::hide::modal', 'modal-add-card');
    this.$emit('refresh');
  }

  resetData() {
    this.dataToPost = {
      title: null,
      subtitle: null,
      description: null,
      description2: null,
      url: null,
      image: null,
      icon: null,
      section_id: null
    };
    this.$refs.observer.reset();
  }

  onModalHide() {
    this.doShow = false;
    this.resetData();
  }

  onModalShown() {
    this.doShow = true;
  }
}
