




















































































import { Component, Vue } from 'vue-property-decorator';
import PostsCard from '../components/PostsCard.vue';
import FilterPostsModal from '../components/PostsFilterModal.vue';
import Panel from '../../../components/panel/Panel.vue';
import { PostTypes } from '../../../interfaces/posts';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'posts/GET_FIELDS',
  mutationType: 'posts/UPDATE_FIELDS'
});

@Component({
  components: { PostsCard, FilterPostsModal, Panel },
  computed: {
    ...mapFields({
      posts: 'posts',
      posts_total: 'posts_total',
      posts_loading: 'posts_loading',
      limit: 'posts_filter.limit',
      page: 'posts_filter.page'
    })
  }
})
export default class Posts extends Vue {
  posts!: PostTypes[];
  posts_total: number;
  posts_loading!: boolean;
  page: number;
  limit: number;
  panelTitle = 'Daftar Artikel';
  panelSubtitle = 'Menampilkan 10 dari 200 data';
  filter = {
    limit: 6,
    page: 1
  };

  async mounted() {
    this.$store.commit('posts/RESET_POSTS_FILTER');
    this.getPosts();
    this.getCategories();
  }

  async getCategories() {
    try {
      await this.$store.dispatch('posts/GET_POSTS_CATEGORY');
    } catch (error) {
      return error;
    }
  }

  async getPosts() {
    try {
      this.posts_loading = true;
      await this.$store.dispatch('posts/GET_POSTS', this.filter);
      this.posts_loading = false;
    } catch (error) {
      return error;
    }
  }

  onLimitChange() {
    this.page = 1;
    this.getPosts();
  }

  onPageChange(selectedPage: number) {
    this.page = selectedPage;
    this.getPosts();
  }
}
