






import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  computed: {}
})
export default class Summary extends Vue {
  // mounted() {}
}
