













































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
@Component({
  components: {},
  computed: {}
})
export default class SocialMediaSettings extends Vue {
  socmed = [];
  loading_get = false;
  loading = false;
  async mounted() {
    this.loading_get = true;
    const { data } = await axios.get('/settings/socmed');
    this.socmed = data.data;
    this.loading_get = false;
  }

  async update(index: number) {
    this.loading = true;
    const value = this.socmed[index];
    await axios.put('/settings/socmed/update', value);
    this.loading = false;
  }
}
