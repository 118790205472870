



























































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  computed: {}
})
export default class Assets extends Vue {
  province = [
    {
      name: 'Nanggroe Aceh Darussalam'
    },
    {
      name: 'Sumatera Utara'
    },
    {
      name: 'Sumatera Selatan'
    },
    {
      name: 'Sumatera Barat'
    },
    {
      name: 'Bengkulu'
    },
    {
      name: 'Riau'
    },
    {
      name: 'Kepulauan Riau'
    },
    {
      name: 'Jambi'
    },
    {
      name: 'Lampung'
    },
    {
      name: 'Bangka Belitung'
    },
    {
      name: 'Kalimantan Barat'
    },
    {
      name: 'Kalimantan Timur'
    },
    {
      name: 'Kalimantan Selatan'
    },
    {
      name: 'Kalimantan Tengah'
    },
    {
      name: 'Kalimantan Utara'
    },
    {
      name: 'Banten'
    },
    {
      name: 'DKI Jakarta'
    },
    {
      name: 'Jawa Barat'
    },
    {
      name: 'Jawa Tengah'
    },
    {
      name: 'DI Yogyakarta'
    },
    {
      name: 'Jawa timur'
    },
    {
      name: 'Bali'
    },
    {
      name: 'Nusa Tenggara Timur'
    },
    {
      name: 'Nusa Tenggara Barat'
    },
    {
      name: 'Gorontalo'
    },
    {
      name: 'Sulawesi Barat'
    },
    {
      name: 'Sulawesi Tengah'
    },
    {
      name: 'Sulawesi Utara'
    },
    {
      name: 'Sulawesi Tenggara'
    },
    {
      name: 'Sulawesi Selatan'
    },
    {
      name: 'Maluku Utara'
    },
    {
      name: 'Maluku'
    },
    {
      name: 'Papua Barat'
    },
    {
      name: 'Papua ( Daerah Khusus )'
    }
  ];
}
