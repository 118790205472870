



































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  components: {},
  computed: {}
})
export default class SectionList extends Vue {
  @Prop(Boolean) loading: boolean;
  @Prop(Boolean) failed: boolean;
  @Prop(Array) sections: any[];
  @Prop(String) selected_section_id: string;

  selectSection(id: string) {
    this.$emit('select_section', id);
  }
}
