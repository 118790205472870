


































































































































































import { Component, Vue } from 'vue-property-decorator';
import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import '@/assets/sass/pages/auth/auth.scss';
extend('email', email);
extend('required', required);

@Component({
  components: {}
})
export default class Signup extends Vue {
  email = '';
  name = '';
  company_name = '';
  mobile_no = '';
  isSuccess = false;
  isFailed = false;
  onSubmit(): void {
    this.$store
      .dispatch('user/SIGN_UP', {
        email: this.email,
        name: this.name,
        company_name: this.company_name,
        mobile_no: this.mobile_no
      })
      .then(() => {
        this.isFailed = false;
        this.isSuccess = true;
      })
      .catch(() => {
        this.isSuccess = false;
        this.isFailed = true;
      });
  }
}
