







































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  computed: {}
})
export default class Assets extends Vue {
  products = [
    {
      img:
        'https://asset.pertaminafuels.com/uploads/20200122204601_original.png',
      name: 'Pertamax Turbo'
    },
    {
      img:
        'https://asset.pertaminafuels.com/uploads/20200122204530_original.png',
      name: 'Pertamax'
    },
    {
      img:
        'https://asset.pertaminafuels.com/uploads/20200122204517_original.png',
      name: 'Pertalite'
    },
    {
      img:
        'https://asset.pertaminafuels.com/uploads/20200122204454_original.png',
      name: 'Pertamina Dex'
    },
    {
      img:
        'https://asset.pertaminafuels.com/uploads/20200122204419_original.png',
      name: 'Dex Lite'
    }
  ];
}
