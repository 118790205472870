





































import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {},
  computed: {}
})
export default class NotFound extends Vue {}
