



















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { PostTypes } from '../../../interfaces/posts';
@Component({
  components: {},
  computed: {}
})
export default class PostsCard extends Vue {
  @Prop(Object) readonly data: PostTypes | undefined;
  isImageLoaded = false;

  get imageUrl() {
    return this.data?.featured_img
      ? this.data.featured_img
      : 'https://via.placeholder.com/1200x628.png';
  }

  get categoryName() {
    return this.data?.category !== null
      ? this.data?.category.name
      : 'Uncategorized';
  }

  loadContent() {
    this.isImageLoaded = true;
  }
}
