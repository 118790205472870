
























































































































































import { Component, Vue } from 'vue-property-decorator';
import { UserInterface } from '@/interfaces/user.js';
import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import '@/assets/sass/pages/auth/auth.scss';
extend('email', email);
extend('required', required);

@Component({
  components: {}
})
export default class Signin extends Vue {
  failed = false;
  loading = false;
  success = false;
  email = '';
  password = '';

  get userInfo(): UserInterface {
    return this.$store.getters['user/user_info'];
  }

  get loginStatus(): boolean {
    return this.$store.getters['user/login_status'];
  }

  onSubmit(): void {
    this.failed = false;
    this.loading = true;
    this.success = false;
    this.$store
      .dispatch('user/SIGN_IN', {
        email: this.email,
        password: this.password
      })
      .then((data) => {
        this.failed = false;
        this.success = true;
        setTimeout(() => {
          this.$store.dispatch('user/SUCCESS_LOGIN', data.data.data);
          this.$router.push('posts');
        }, 2000);
      })
      .catch(() => {
        this.failed = true;
        this.loading = false;
      });
  }
}
