var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-category","hide-footer":"","title":"Add new category"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"refs":"addForm","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.add)}}},[_c('div',{staticClass:"row d-flex align-items-end"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"Category name (ID)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"text-danger mr-1"},[_vm._v("*")]),_vm._v(" Category name (ID) ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataToPost.name),expression:"dataToPost.name"}],staticClass:"form-control form-control-solid",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.dataToPost.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataToPost, "name", $event.target.value)}}})])]}}],null,true)})],1),(_vm.main_config.translation)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"Category name (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_c('span',{staticClass:"d-none"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"text-danger mr-1"},[_vm._v("*")]),_vm._v(" Category name (EN) ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataToPost.name_en),expression:"dataToPost.name_en"}],staticClass:"form-control form-control-solid",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.dataToPost.name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataToPost, "name_en", $event.target.value)}}})])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group d-flex justify-content-end"},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.loading_add,"size":"sm","variant":"primary"}},[(_vm.loading_add)?_c('div',[_c('span',{staticClass:"spinner spinner-white mr-6"})]):_c('div',[_c('i',{staticClass:"la la-plus"}),_vm._v(" Submit ")])])],1)])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }