













































































































































import { Component, Vue } from 'vue-property-decorator';
import UploadInput from '@/components/UploadInput/UploadInput.vue';
import axios from 'axios';
@Component({
  components: { UploadInput },
  computed: {}
})
export default class GeneralSettings extends Vue {
  loading = false;
  loading_submit = false;
  dataToPost = {
    company_name: '',
    email: '',
    phone: '',
    fax: '',
    address: '',
    light_logo: '',
    dark_logo: ''
  };

  async mounted() {
    this.loading = true;
    const { data } = await axios.get('/settings/general');
    this.dataToPost = data.data[0];
    this.loading = false;
  }

  async submit() {
    this.loading_submit = true;
    await axios.put('/settings/general/update', this.dataToPost);
    this.loading_submit = false;
  }
}
