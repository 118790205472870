





































import { Component, Vue } from 'vue-property-decorator';
@Component({
  computed: {}
})
export default class UpdateSaldo extends Vue {
  file = {};

  setFile(e: any): void {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      this.file = file;
    }
  }

  updateSaldo(): void {
    this.$store.dispatch('member/UPDATE_SALDO', this.file).then((res) => {
      this.$swal('Success', 'Success update saldo', 'success');
      this.file = {};
    });
  }
}
