
















































































































































































































































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import CategoryModal from '../components/CategoryModal.vue';

interface DataToPostType {
  id: string;
  title: string;
  content: string;
  tags: string[];
  image: string;
  category_id: string;
  visibility: string;
  status: string;
}

@Component({
  components: { Editor, CategoryModal },
  computed: {}
})
export default class PostsDetail extends Vue {
  tagInput = '';
  uploadLoading = false;
  categoryLoading = true;
  savePostLoading = false;
  deletePostLoading = false;
  dataToPost: DataToPostType = {
    id: '',
    title: '',
    content: '',
    tags: [],
    image: '',
    category_id: '',
    visibility: 'Public',
    status: 'Published'
  };

  get categories() {
    return this.$store.getters['posts/CATEGORIES'];
  }

  get post_id() {
    return this.$route.params.id;
  }

  async mounted() {
    try {
      this.dataToPost.title = 'Loading...';
      await this.$store.dispatch('posts/GET_POSTS_CATEGORY');
      const { data } = await this.$store.dispatch(
        'posts/GET_POSTS_DETAIL',
        this.post_id
      );
      this.dataToPost.id = data.id;
      this.dataToPost.title = data.title;
      this.dataToPost.content = data.content;
      this.dataToPost.visibility = data.visibility;
      this.dataToPost.status = data.status;
      this.dataToPost.category_id =
        data.category_id === null ? '' : data.category_id;
      this.dataToPost.image = data.featured_img;
      this.dataToPost.tags = data.tags !== '' ? data.tags.split(',') : [];
      this.categoryLoading = false;
    } catch (error) {
      return error;
    }
  }

  addTags() {
    if (this.tagInput) {
      this.dataToPost.tags.push(this.tagInput);
      this.tagInput = '';
    }
  }
  removeTag(index: number) {
    this.dataToPost.tags.splice(index, 1);
  }

  triggerUpload() {
    const elem: HTMLElement = this.$refs.imageInput as HTMLElement;
    elem.click();
  }

  removeImage() {
    this.dataToPost.image = '';
    const elm: any = this.$refs.imageInput as HTMLElement;
    elm.value = '';
  }

  uploadImage(event: any) {
    this.uploadLoading = true;
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('file', image);
    formData.append('directory', 'posts/');
    const headers = { 'Content-Type': 'multipart/form-data' };
    axios
      .post('/upload', formData, { headers })
      .then((res) => {
        this.dataToPost.image = res.data.data.url;
      })
      .finally(() => {
        this.uploadLoading = false;
      });
  }

  async deletePost() {
    try {
      this.$swal({
        title: 'Are you sure want to delete this category?',
        text: 'Every posts using this category would be uncategorized',
        showCancelButton: true,
        icon: 'info',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#03BBB2',
        denyButtonText: `Cancel`
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deletePostLoading = true;
          await this.$store.dispatch('posts/DELETE_POST', this.dataToPost.id);
          this.deletePostLoading = false;
          this.$router.push({ path: '/posts' });
        }
      });
    } catch (error) {
      return error;
    }
  }

  async savePost() {
    this.savePostLoading = true;
    try {
      await this.$store.dispatch('posts/UPDATE_POST', this.dataToPost);
      this.savePostLoading = false;
    } catch (error) {
      return error;
    }
  }
}
