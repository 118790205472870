






































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import UploadInput from '../UploadInput/UploadInput.vue';
@Component({
  components: { UploadInput },
  computed: {}
})
export default class Rates extends Vue {
  rates = [];
  loading = false;
  selectedRate: any = null;
  loading_update = false;
  loading_insert = false;
  loading_delete = false;
  dataToPost = {
    name: '',
    long_name: '',
    image: '',
    bank_notes_sell: 0,
    bank_notes_buy: 0,
    counter_rate_buy: 0,
    counter_rate_sell: 0
  };
  async updateRates(item: any) {
    const res = await axios.get('rates/detail/' + item.id);
    this.selectedRate = res.data.data[0];
    this.$root.$emit('bv::show::modal', 'modal-update');
  }
  async insertSubmit() {
    this.loading_insert = true;
    await axios.post('rates/insert', this.dataToPost);
    this.loading_insert = false;
    this.getRates();
    this.$root.$emit('bv::hide::modal', 'modal-add');
  }
  async updateSubmit() {
    this.loading_update = true;
    await axios.put('rates/update', this.selectedRate);
    this.loading_update = false;
    this.getRates();
    this.$root.$emit('bv::hide::modal', 'modal-update');
  }

  async deleteRates() {
    const id = this.selectedRate?.id;
    this.$swal({
      title: 'Are you sure want to delete this card?',
      text: 'Your action could not be reverted',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_delete = true;
        await axios.delete('/rates/delete/' + id);
        this.$root.$emit('bv::hide::modal', 'modal-update');
        this.loading_delete = false;
        this.getRates();
      }
    });
  }
  mounted() {
    this.getRates();
  }

  async getRates() {
    this.loading = true;
    const res = await axios.get('rates');
    this.rates = res.data.data;
    this.loading = false;
  }
}
