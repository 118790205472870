













































import { Component, Vue } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

@Component({
  components: {
    DatePicker
  },
  computed: {}
})
export default class UpdateSaldoDetail extends Vue {
  file = {};

  // mounted() {}

  setFile(e: any): void {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      this.file = file;
    }
  }

  updateSaldoDetail(): void {
    this.$store
      .dispatch('member/UPDATE_SALDO_DETAIL', this.file)
      .then((res) => {
        this.$swal('Success', 'Success update saldo detail', 'success');
        this.file = {};
      });
  }
}
