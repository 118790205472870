

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  computed: {}
})
export default class SelectData extends Vue {
  @Prop(Array) data: any[];
  @Prop(Boolean) loading: boolean;
  @Prop(String) name: string;
  @Prop(String) blank_name: string;
  @Prop(Boolean) required: boolean;
  @Prop(Boolean) failed: boolean;
  @Prop(Boolean) blank: boolean;
  @Prop(Boolean) select_all: boolean;
  @Prop(String) select_all_name: string;

  selectData(event: any) {
    const value = event.target.value;
    this.$emit('input', value);
    this.$emit('changed', value);
  }

  retry() {
    this.$emit('retry');
  }
}
