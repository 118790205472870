




























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import axios from 'axios';
import MainConfig from '@/config/config';
@Component({
  components: {},
  computed: {}
})
export default class ModalUpdateCategory extends Vue {
  @Prop(String) selected_category: string;

  dataToPost = {
    name: '',
    name_en: ''
  };
  loading = true;
  main_config = MainConfig;
  loading_update = false;

  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onModalShown() {
    this.loading = true;
    const { data } = await axios.get(
      '/files/category?id=' + this.selected_category
    );
    this.loading = false;
    this.dataToPost.name = data.data.data[0].name;
    this.dataToPost.name_en = data.data.data[0].name_en;
  }

  async update() {
    if (!MainConfig.translation) this.dataToPost.name_en = this.dataToPost.name;
    this.loading_update = true;
    try {
      await axios.put(
        'files/category/update/' + this.selected_category,
        this.dataToPost
      );
      this.dataToPost.name = '';
      this.dataToPost.name_en = '';
      this.loading_update = false;
      await this.$nextTick();
      this.$refs.observer.reset();
      this.$root.$emit('bv::hide::modal', 'modal-update-category');
      this.$emit('refresh');
    } catch (error) {
      this.loading_update = false;
    }
  }
}
