



























































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import UploadInput from '@/components/UploadInput/UploadInput.vue';
import SelectData from '@/components/SelectData.vue';
import Editor from '@tinymce/tinymce-vue';
import MainConfig from '@/config/config';
import axios from 'axios';
@Component({
  components: { UploadInput, SelectData, Editor },
  computed: {}
})
export default class Sliders extends Vue {
  @Prop(Object) selected_section: any;
  mainconfig = MainConfig;
  sliders = [];
  loading_get_sliders = false;
  loading_process = false;
  edit_mode = false;
  is_mobile = false;
  selected_slider = '';
  dataToPost = {
    id: '',
    section_id: '',
    name: '',
    url: '',
    action_text: '',
    content: '',
    background: '',
    background_mobile: ''
  };

  mounted() {
    this.getSliders();
  }

  async getSliders() {
    this.loading_get_sliders = true;
    const { data } = await axios.get(
      '/sliders/get?section_id=' + this.selected_section.id
    );
    this.sliders = data.data;
    this.dataToPost.section_id = this.selected_section.id;
    this.loading_get_sliders = false;
  }

  resetData() {
    this.dataToPost.id = '';
    this.dataToPost.section_id = this.selected_section.id;
    this.dataToPost.name = '';
    this.dataToPost.url = '';
    this.dataToPost.action_text = '';
    this.dataToPost.content = '';
    this.dataToPost.background = '';
    this.dataToPost.background_mobile = '';
    this.edit_mode = false;
  }

  onSliderSelected(value: string) {
    if (value) {
      const filteredSlider: any = this.sliders.find(
        (item: any) => item.id === value
      );
      this.dataToPost.id = filteredSlider.id;
      this.dataToPost.section_id = this.selected_section.id;
      this.dataToPost.name = filteredSlider.name;
      this.dataToPost.url = filteredSlider.url;
      this.dataToPost.action_text = filteredSlider.action_text;
      this.dataToPost.content = filteredSlider.content;
      this.dataToPost.background = filteredSlider.background;
      this.dataToPost.background_mobile = filteredSlider.background_mobile;
      this.edit_mode = true;
    } else {
      this.resetData();
    }
  }

  async add() {
    this.loading_process = true;
    await axios.post('/sliders/add', this.dataToPost);
    this.loading_process = false;
    this.resetData();
    await this.getSliders();
  }

  async update() {
    this.loading_process = true;
    await axios.put('/sliders/update/' + this.dataToPost.id, this.dataToPost);
    this.loading_process = false;
    await this.getSliders();
  }

  async deleteSlider() {
    this.$swal({
      title: 'Are you sure want to delete this slider?',
      text: "Your action couldn't be reverted",
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          this.sliders = [];
          this.loading_process = true;
          await axios.delete('/sliders/delete/' + this.dataToPost.id);
          this.loading_process = false;
          this.getSliders();
          this.resetData();
        } catch (error) {
          this.loading_process = false;
          this.getSliders();
          this.resetData();
        }
      }
    });
  }
}
