

































































import { Component, Vue } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

@Component({
  components: {
    DatePicker
  },
  computed: {}
})
export default class DownloadDataMember extends Vue {
  fields: Array<object> = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'username',
      label: 'Username'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'cabang',
      label: 'Cabang'
    },
    {
      key: 'wilayah',
      label: 'Wilayah'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'active',
      label: 'Active'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ];
  items: Array<object> = [];

  tanggal_masuk = [];
  tanggal_kepesertaan = [];
  saldo_periode = [];

  wilayah: Array<object> = [
    { value: null, text: 'Select wilayah' },
    { value: '09', text: '09' },
    { value: '1', text: '1' },
    { value: 'A', text: 'A' }
  ];
  status: Array<object> = [
    { value: null, text: 'Select status' },
    { value: 'approval', text: 'Approval' },
    { value: 'pending', text: 'Pending' },
    { value: 'inactive', text: 'Inactive' }
  ];

  // mounted() {}

  searchData(): void {
    const data: any = {
      tanggal_kepesertaan_start: '',
      tanggal_kepesertaan_end: '',
      saldo_periode_start: '',
      saldo_periode_end: ''
    };
    if (this.tanggal_masuk && this.tanggal_masuk.length > 0) {
      const tanggal_masuk = this.tanggal_masuk;
      data['tanggal_masuk_start'] = tanggal_masuk[0];
      data['tanggal_masuk_end'] = tanggal_masuk[1];
    }

    if (this.tanggal_kepesertaan && this.tanggal_kepesertaan.length > 0) {
      const tanggal_kepesertaan = this.tanggal_kepesertaan;
      data['tanggal_kepesertaan_start'] = tanggal_kepesertaan[0];
      data['tanggal_kepesertaan_end'] = tanggal_kepesertaan[1];
    }

    if (this.saldo_periode && this.saldo_periode.length > 0) {
      const saldo_periode = this.saldo_periode;
      data['saldo_periode_start'] = saldo_periode[0];
      data['saldo_periode_end'] = saldo_periode[1];
    }

    this.$store.dispatch('member/DOWNLOAD_MEMBER_PERIODE', data).then((res) => {
      const url = res.data.url;
      window.location.href = url;
    });
  }
}
