


















































































import { Component, Vue } from 'vue-property-decorator';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'member/GET_FIELDS',
  mutationType: 'member/UPDATE_FIELDS'
});

@Component({
  computed: {
    ...mapFields({
      page: 'member_params.page',
      limit: 'member_params.limit',
      keyword: 'member_params.keyword',
      selected_wilayah: 'member_params.wilayah',
      selected_status: 'member_params.status'
    })
  }
})
export default class Members extends Vue {
  fields: Array<object> = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'nip',
      label: 'Username'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'cabang',
      label: 'Cabang'
    },
    {
      key: 'wilayah',
      label: 'Wilayah'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'active',
      label: 'Active'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ];
  items: Array<object> = [];

  wilayah: Array<object> = [
    { value: '', text: 'Select wilayah' },
    { value: '09', text: '09' },
    { value: '1', text: '1' },
    { value: 'A', text: 'A' }
  ];
  status: Array<object> = [
    { value: '', text: 'Select status' },
    { value: 'approval', text: 'Approval' },
    { value: 'pending', text: 'Pending' },
    { value: 'inactive', text: 'Inactive' }
  ];

  mounted() {
    this.getdata();
  }

  getdata(): void {
    this.$store.dispatch('member/GET_MEMBER').then((res: any) => {
      const datas = res.data.data;
      this.items = datas;
    });
  }

  // editData(data: any): void {}

  goto(url: string): void {
    this.$router.push(url);
  }

  resetParams(): void {
    this.$store.commit('member/RESET_STATE_PARAMS');
    this.getdata();
  }
}
