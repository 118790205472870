


















































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import UploadInput from '../../../../components/UploadInput/UploadInput.vue';
import Editor from '@tinymce/tinymce-vue';
import Sliders from '@/components/sliders/Sliders.vue';
import Cards from '@/components/cards/Cards.vue';
import Rates from '@/components/rates/Rates.vue';
import axios from 'axios';

@Component({
  components: {
    UploadInput,
    Editor,
    Rates,
    Sliders,
    Cards
  },
  computed: {}
})
export default class SectionInformation extends Vue {
  @Prop(String) selected_section_id: string;

  loading_get = true;
  loading_submit = false;
  dataToPost: any = {};

  get configuration() {
    if (this.dataToPost.configuration) {
      return JSON.parse(this.dataToPost.configuration);
    } else {
      return null;
    }
  }

  @Watch('selected_section_id', { immediate: true, deep: false })
  async onSelectedSectionIdChanged() {
    this.loading_get = true;
    this.dataToPost = {};
    const { data } = await axios.get(
      '/sections/get/detail/' + this.selected_section_id
    );
    this.dataToPost = data.data;
    this.loading_get = false;
  }

  generateGrid(condition: any) {
    return condition ? 'col-6 ' : 'col-12 ';
  }

  async submit() {
    this.loading_submit = true;
    await axios.put(
      '/sections/update/' + this.selected_section_id,
      this.dataToPost
    );
    this.loading_submit = false;
  }
}
