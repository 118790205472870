




























































































import { Component, Vue } from 'vue-property-decorator';
import { mixin as clickaway } from 'vue-clickaway';
// import { UserInterface } from '@/interfaces/user.ts';

@Component({
  components: {},
  mixins: [clickaway]
})
export default class Headbar extends Vue {
  profileVisiblity = false;
  online_activity = false;

  toggleProfileVisibility(): void {
    this.profileVisiblity = !this.profileVisiblity;
  }

  logout(): void {
    // this.userInfo = null;
    this.$store.dispatch('user/LOGOUT').then(() => {
      this.$router.push('/');
    });
  }

  showOnlineStatus(): void {
    if (this.online_activity) {
      this.$notify({
        group: 'online_activity',
        title: 'You are online now',
        type: 'primary',
        text:
          'Welcome back, assignment system will be normal again. This activity will be recorded',
        duration: 5000
      });
    } else {
      this.$notify({
        group: 'online_activity',
        title: 'You are offline now',
        type: 'error',
        text:
          'System will not assign you task at this moment. This activity will be recorded.',
        duration: 5000
      });
    }
  }
}
