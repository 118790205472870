














































































































































































































import { Component, Vue } from 'vue-property-decorator';
import UploadInput from '../../../../components/UploadInput/UploadInput.vue';
import 'vue2-datepicker/index.css';
import Editor from '@tinymce/tinymce-vue';

interface DataToPostType {
  section_id: string;
  title: string;
  subtitle: string;
  description: string;
  content: string;
  background: string;
  background_size: string;
  background_responsive: string;
  background_responsive_size: string;
  icon: string;
  file: string;
  list: string[];
  actions: ActionsType[];
}

interface ActionsType {
  type: string;
  text: string;
  content: string;
}

@Component({
  components: {
    UploadInput,
    Editor
  },
  computed: {}
})
export default class SectionItemAddModal extends Vue {
  loading = false;
  dataToPost: DataToPostType = {
    section_id: '',
    title: '',
    subtitle: '',
    description: '',
    content: '',
    background: '',
    background_size: '200x200',
    background_responsive: '',
    background_responsive_size: '200x200',
    icon: '',
    file: '',
    actions: [],
    list: ['First list']
  };

  get selected_section() {
    return this.$store.getters['sections/SELECTED_SECTION'];
  }

  get item_configuration() {
    const test = this.$store.getters[
      'sections/SELECTED_SECTION'
    ].item_configuration.split(',');
    return test;
  }

  addNewList() {
    this.dataToPost.list.push('New list');
  }

  generateGrid(condition: any) {
    return condition ? 'col-6 ' : 'col-12 ';
  }

  onModalClose() {
    this.dataToPost = {
      section_id: '',
      title: '',
      subtitle: '',
      description: '',
      content: '',
      background: '',
      background_size: '200x200',
      background_responsive: '',
      background_responsive_size: '200x200',
      icon: '',
      file: '',
      actions: [],
      list: ['First list']
    };
  }

  onModalOpen() {
    for (
      let index = 0;
      index < parseInt(this.selected_section.total_section_item_action);
      index++
    ) {
      this.dataToPost.actions.push({ type: 'Link', text: '', content: '' });
    }
  }

  async submit() {
    this.loading = true;
    this.dataToPost.section_id = this.selected_section.id;
    await this.$store.dispatch('sections/ADD_SECTION_ITEM', this.dataToPost);
    this.loading = false;
    this.$root.$emit('bv::hide::modal', 'modal-section-item-add');
    await this.$store.dispatch(
      'sections/GET_SECTION_ITEMS',
      this.selected_section.id
    );
  }
}
