








































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  computed: {}
})
export default class Panel extends Vue {
  @Prop(String) readonly title: string;
  @Prop(String) readonly subtitle: string;
}
