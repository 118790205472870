












































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { createHelpers } from 'vuex-map-fields';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const { mapFields } = createHelpers({
  getterType: 'member/GET_FIELDS',
  mutationType: 'member/UPDATE_FIELDS'
});

@Component({
  components: {
    DatePicker
  },
  computed: {
    ...mapFields({
      cabang_utama: 'memberForm.cabang_utama',
      cabang: 'memberForm.cabang',
      wilayah: 'memberForm.wilayah',
      nip: 'memberForm.nip',
      password: 'memberForm.password',
      email: 'memberForm.email',
      active: 'memberForm.active',
      status: 'memberForm.status',
      status_pasif: 'memberForm.status_pasif',
      status_pengurus: 'memberForm.status_pengurus',
      status_update: 'memberForm.status_update',
      name: 'memberForm.name',
      no_ktp: 'memberForm.no_ktp',
      alamat_ktp: 'memberForm.alamat_ktp',
      alamat_domisili: 'memberForm.alamat_domisili',
      kota_ktp: 'memberForm.kota_ktp',
      kota_domisili: 'memberForm.kota_domisili',
      kode_pos_ktp: 'memberForm.kode_pos_ktp',
      kode_pos_domisili: 'memberForm.kode_pos_domisili',
      tempat_lahir: 'memberForm.tempat_lahir',
      tanggal_lahir: 'memberForm.tanggal_lahir',
      jenis_kelamin: 'memberForm.jenis_kelamin',
      status_kawin: 'memberForm.status_kawin',
      npwp: 'memberForm.npwp',
      telpon_rumah: 'memberForm.telpon_rumah',
      no_hp: 'memberForm.no_hp',
      tanggal_masuk_bca: 'memberForm.tanggal_masuk_bca',
      tanggal_kepesertaan: 'memberForm.tanggal_kepesertaan',
      saldo_periode: 'memberForm.saldo_periode',
      saldo_iuran_peserta: 'memberForm.saldo_iuran_peserta',
      saldo_iuran_pemberi_kerja: 'memberForm.saldo_iuran_pemberi_kerja',
      saldo_hasil_pengembangan: 'memberForm.saldo_hasil_pengembangan',
      saldo_spi: 'memberForm.saldo_spi',
      saldo_iuran_sukarela: 'memberForm.saldo_iuran_sukarela',
      saldo_sukarela_hasil_pengembangan:
        'memberForm.saldo_sukarela_hasil_pengembangan',
      saldo_sukarela_spi: 'memberForm.saldo_sukarela_spi'
    })
  }
})
export default class MemberForm extends Vue {
  id = null;
  is_edited = false;

  fields: Array<object> = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'username',
      label: 'Username'
    },
    {
      key: 'name',
      label: 'Name'
    },
    {
      key: 'cabang',
      label: 'Cabang'
    },
    {
      key: 'wilayah',
      label: 'Wilayah'
    },
    {
      key: 'email',
      label: 'Email'
    },
    {
      key: 'active',
      label: 'Active'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ];

  selected_status = null;
  selected_wilayah = null;

  list_status: Array<object> = [
    { value: null, text: 'Select status' },
    { value: 'approval', text: 'Approval' },
    { value: 'pending', text: 'Pending' },
    { value: 'inactive', text: 'Inactive' }
  ];

  list_active: Array<string> = ['No', 'Yes'];
  list_status_pasif: Array<string> = ['No', 'Yes'];
  list_status_pengurus: Array<string> = ['U', 'P'];
  list_status_update: Array<string> = ['No', 'Yes'];
  list_jenis_kelamin: Array<string> = ['Pria', 'Wanita'];
  list_status_kawin: Array<string> = ['Menikah', 'Single'];

  mounted() {
    this.$store.commit('member/RESET_STATE_FORM');
    const router: any = this.$router;
    const id = router.history.current.params.id;
    if (id) {
      this.id = id;
      this.is_edited = true;
      this.getDetailMember();
    }
  }

  goto(url: string): void {
    this.$router.push(url);
  }

  getDetailMember(): void {
    this.$store.dispatch('member/GET_DETAIL_MEMBER', this.id);
  }

  makeid(): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  submitForm(): void {
    if (this.is_edited) {
      this.$store.dispatch('member/UPDATE_MEMBER').then(() => {
        this.$router.push('/members/members');
      });
    } else {
      this.$store.dispatch('member/CREATE_MEMBER').then(() => {
        this.$router.push('/members/members');
      });
    }
  }
}
