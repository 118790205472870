




























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import UploadInput from '../../../../components/UploadInput/UploadInput.vue';
import 'vue2-datepicker/index.css';
import Editor from '@tinymce/tinymce-vue';

@Component({
  components: {
    UploadInput,
    Editor
  },
  computed: {}
})
export default class SectionItemUpdateModal extends Vue {
  getLoading = true;
  saveLoading = false;
  list: string[];
  dataToPost = null;

  get selected_section() {
    return this.$store.getters['sections/SELECTED_SECTION'];
  }

  get selected_section_item() {
    return this.$store.getters['sections/SELECTED_SECTION_ITEM'];
  }

  get item_configuration() {
    return this.$store.getters[
      'sections/SELECTED_SECTION'
    ].item_configuration.split(',');
  }

  generateGrid(condition: any) {
    return condition ? 'col-6 ' : 'col-12 ';
  }

  addNewList() {
    this.list.push('New list');
  }

  async submit() {
    this.saveLoading = true;
    await this.$store.dispatch('sections/UPDATE_SECTION_ITEM', this.dataToPost);
    this.saveLoading = false;
    this.$root.$emit('bv::hide::modal', 'modal-section-item-update');
    await this.$store.dispatch(
      'sections/GET_SECTION_ITEMS',
      this.selected_section.id
    );
  }

  async onModalShown() {
    this.getLoading = true;
    const { data } = await this.$store.dispatch(
      'sections/GET_SECTION_ITEM',
      this.selected_section_item.id
    );
    this.dataToPost = data;
    this.getLoading = false;
  }

  onModalClose() {
    this.getLoading = true;
  }
}
