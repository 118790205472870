















































































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'posts/GET_FIELDS',
  mutationType: 'posts/UPDATE_FIELDS'
});

@Component({
  components: {
    DatePicker
  },
  computed: {
    ...mapFields({
      posts_loading: 'posts_loading',
      title: 'posts_filter.title',
      category_id: 'posts_filter.category_id',
      status: 'posts_filter.status',
      visibility: 'posts_filter.visibility',
      start_date: 'posts_filter.start_date',
      end_date: 'posts_filter.end_date',
      limit: 'posts_filter.limit',
      page: 'posts_filter.page'
    })
  }
})
export default class PostsFilterModal extends Vue {
  posts_loading!: boolean;
  title!: string;
  category_id!: string;
  status!: string;
  visibility!: string;
  start_date!: string;
  end_date!: string;
  limit!: number;
  page!: number;
  selectedDate = ['', ''];

  get categories() {
    return this.$store.getters['posts/CATEGORIES'];
  }

  get parsedStartDate() {
    return this.selectedDate[0]
      ? moment(this.selectedDate[0] + ' ' + '00:00:00').format(
          'YYYY-MM-DD HH:mm:ss'
        )
      : '';
  }

  get parsedEndDate() {
    return this.selectedDate[1]
      ? moment(this.selectedDate[1] + ' ' + '23:59:59').format(
          'YYYY-MM-DD HH:mm:ss'
        )
      : '';
  }

  resetFilter() {
    this.$store.commit('posts/RESET_POSTS_FILTER');
    this.selectedDate = ['', ''];
  }

  async filterPosts() {
    this.$root.$emit('bv::toggle::modal', 'modal-post-filter', '#btnToggle');
    this.start_date = this.parsedStartDate;
    this.end_date = this.parsedEndDate;
    this.page = 1;
    this.posts_loading = true;
    await this.$store.dispatch('posts/GET_POSTS');
    this.posts_loading = false;
  }
}
