





































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import 'vue2-datepicker/index.css';
import Editor from '@tinymce/tinymce-vue';
import { ValidationObserver } from 'vee-validate';
import UploadInput from '../UploadInput/UploadInput.vue';
import axios from 'axios';

@Component({
  components: {
    Editor,
    UploadInput
  },
  computed: {}
})
export default class CardsUpdateModal extends Vue {
  @Prop(String) selected_card: string;
  @Prop(Object) selected_section: any;
  doShow = false;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  loading = false;
  loading_data = true;

  dataToPost = {
    title: null,
    subtitle: null,
    description: null,
    description2: null,
    url: null,
    image: null,
    icon: null,
    section_id: null
  };

  get configuration() {
    return JSON.parse(this.selected_section.configuration);
  }
  get fields() {
    return this.configuration.fields;
  }

  async submit() {
    this.loading = true;
    await axios.put('/cards/update/' + this.selected_card, this.dataToPost);
    this.loading = false;
    this.$root.$emit('bv::hide::modal', 'modal-update-card');
    this.$emit('refresh');
  }

  resetData() {
    this.dataToPost = {
      title: null,
      subtitle: null,
      description: null,
      description2: null,
      url: null,
      image: null,
      icon: null,
      section_id: this.selected_section.id
    };
    this.$refs.observer.reset();
    this.loading_data = true;
  }

  async onModalShown() {
    this.doShow = true;
    this.loading_data = true;
    const { data } = await axios.get(
      '/cards/get/detail?id=' + this.selected_card
    );
    this.dataToPost.title = data.data.title;
    this.dataToPost.subtitle = data.data.subtitle;
    this.dataToPost.description = data.data.description;
    this.dataToPost.description2 = data.data.description2;
    this.dataToPost.url = data.data.url;
    this.dataToPost.image = data.data.image;
    this.dataToPost.icon = data.data.icon;
    this.dataToPost.section_id = data.data.section_id;
    this.loading_data = false;
  }
  onModalHide() {
    this.doShow = false;
    this.resetData();
  }
}
