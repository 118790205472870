






















































































































import { Component, Vue } from 'vue-property-decorator';
import AddModal from './AddModal.vue';
import UpdateModal from './UpdateModal.vue';

@Component({
  components: { AddModal, UpdateModal },
  computed: {}
})
export default class SectionItem extends Vue {
  disableItems = false;
  get loading() {
    return this.$store.getters['sections/GET_SECTION_ITEMS_LOADING'];
  }

  get section_items() {
    return this.$store.getters['sections/SECTION_ITEMS'];
  }

  get selected_section() {
    return this.$store.getters['sections/SELECTED_SECTION'];
  }

  get selected_section_item() {
    return this.$store.getters['sections/SELECTED_SECTION_ITEM'];
  }

  selectSectionItem(data: any) {
    this.$store.commit('sections/SET_SELECTED_SECTION_ITEM', data);
  }

  async decreaseOrder(id: string) {
    this.disableItems = true;
    await this.$store.dispatch('sections/DECREASE_SECTION_ITEM_ODER', id);
    this.$store.dispatch(
      'sections/GET_SECTION_ITEMS',
      this.selected_section.id
    );
    this.disableItems = false;
  }

  async increaseOrder(id: string) {
    this.disableItems = true;
    await this.$store.dispatch('sections/INCREASE_SECTION_ITEM_ODER', id);
    this.$store.dispatch(
      'sections/GET_SECTION_ITEMS',
      this.selected_section.id
    );
    this.disableItems = false;
  }

  deleteSectionItem(id: string) {
    this.$swal({
      title: 'Are you sure want to delete this category?',
      text: 'Every posts using this category would be uncategorized',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then((result) => {
      this.$store.commit('sections/SET_SECTION_ITEMS', []);
      this.$store.commit('sections/SET_GET_SECTION_ITEMS_LOADING', true);
      if (result.isConfirmed) {
        // this.deleteLoading = true;
        this.$store.dispatch('sections/DELETE_SECTION_ITEM', id).then(() => {
          this.$store.dispatch(
            'sections/GET_SECTION_ITEMS',
            this.selected_section.id
          );
        });
      }
    });
  }
}
