




























































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import MainConfig from '@/config/config';
import UploadModal from '../components/UploadModal.vue';
import FilterModal from '../components/FilterModal.vue';
import { checkFileExtension } from '@/helper/helper';

@Component({
  components: { UploadModal, FilterModal },
  computed: {}
})
export default class Assets extends Vue {
  filterData: any = {
    limit: 8,
    page: 1
  };
  main_config = MainConfig;
  loading = false;
  failed = false;
  files = [];
  total_rows = 0;
  mounted() {
    this.getFiles();
  }

  checkFileExtension(filename: string) {
    return checkFileExtension(filename);
  }

  onPageChange(selectedPage: number) {
    this.filterData.page = selectedPage;
    this.getFiles();
  }

  async changeFilterDataValue(data: any) {
    this.filterData.name = data.name;
    this.filterData.visibility = data.visibility;
    this.filterData.category_id = data.category_id;
    this.filterData.subcategory_id = data.subcategory_id;
    this.filterData.page = 1;
    await this.getFiles();
  }

  async getFiles() {
    try {
      this.failed = false;
      this.loading = true;
      const queryString = new URLSearchParams(this.filterData).toString();
      const data = await axios.get(`/files/get?${queryString}`);
      this.files = data.data.data.data;
      this.total_rows = data.data.data.pagination.total_rows;
      this.loading = false;
    } catch (error) {
      this.failed = true;
      this.loading = false;
    }
  }
}
