












































































































































































import { Component, Vue } from 'vue-property-decorator';
import UploadInput from '@/components/UploadInput/UploadInput.vue';
import SelectData from '@/components/SelectData.vue';
import MainConfig from '@/config/config';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', required);
import axios from 'axios';

@Component({
  components: { UploadInput, SelectData },
  computed: {}
})
export default class UploadModal extends Vue {
  loading = false;
  loading_category = true;
  loading_subcategory = true;
  categories: any = [];
  subcategories: any = [];
  failed_category = false;
  failed_subcategory = false;
  hasSubcategory = false;
  dataToPost = {
    name: '',
    name_en: '',
    file: '',
    file_en: '',
    category_id: '',
    subcategory_id: '',
    visibility: 'Public'
  };

  main_config = MainConfig;

  onModalShown() {
    this.getCategories();
  }

  retryCategories() {
    this.getCategories();
  }

  async getCategories() {
    try {
      this.loading_category = true;
      this.failed_category = false;
      const { data } = await axios.get(
        `/files/category?limit=100&page=1&parent_id=null`
      );
      this.categories = data.data.data;
      this.loading_category = false;
    } catch (error) {
      this.failed_category = true;
      this.loading_category = false;
    }
  }

  async selectCategory(value: string) {
    try {
      const parent = this.categories.find((item: any) => item.id === value);

      this.hasSubcategory = true;
      this.loading_subcategory = true;
      this.failed_subcategory = false;
      if (value !== '') {
        const { data } = await axios.get(
          `/files/category?limit=100&page=1&parent_id=${parent.id}`
        );
        this.subcategories = data.data.data;
        if (this.subcategories.length > 0) {
          this.dataToPost.subcategory_id = this.subcategories[0].id;
        } else {
          this.hasSubcategory = false;
          this.dataToPost.subcategory_id = '';
        }
        this.loading_subcategory = false;
      } else {
        this.hasSubcategory = false;
        this.subcategories = [];
        this.loading_subcategory = false;
      }
    } catch (error) {
      this.failed_subcategory = true;
      this.loading_subcategory = false;
    }
  }

  resetData() {
    this.loading_category = true;
    this.dataToPost.name = '';
    this.dataToPost.file = '';
    this.dataToPost.name_en = '';
    this.dataToPost.file_en = '';
    this.dataToPost.category_id = '';
    this.dataToPost.subcategory_id = '';
    this.dataToPost.visibility = 'Public';
    this.categories = [];
  }

  async onSubmit() {
    try {
      this.loading = true;
      if (!this.main_config.translation) {
        this.dataToPost.name_en = this.dataToPost.name;
        this.dataToPost.file_en = this.dataToPost.file;
      }
      await axios.post(`/files/upload`, this.dataToPost);
      this.$root.$emit('bv::hide::modal', 'modal-upload');
      this.$emit('reload');
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }
}
