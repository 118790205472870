

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
@Component({
  components: {},
  computed: {}
})
export default class UploadInput extends Vue {
  @Prop(String) directory: string;
  @Prop(Boolean) encrypt: boolean;
  @Prop(Boolean) overwrite: boolean;
  @Prop(Boolean) loading: boolean;
  @Prop(Boolean) required: boolean;
  @Prop(String) name: string;
  @Prop(String) accept: string;

  value = '';
  uploadLoading = false;
  failedUpload = false;
  successUpload = false;
  failedUploadFile: any = null;

  triggerUpload() {
    const elem: HTMLElement = this.$refs.imageInput as HTMLElement;
    elem.click();
  }

  async uploadImage() {
    const elem: HTMLFormElement = this.$refs.imageInput as HTMLFormElement;
    this.failedUploadFile = null;
    this.uploadLoading = true;
    this.failedUpload = false;
    this.successUpload = false;
    const encrypt = this.encrypt ? 'true' : 'false';
    const overwrite = this.overwrite ? 'true' : 'false';
    const file = elem.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('directory', this.directory);
    formData.append('encrypt', encrypt);
    formData.append('overwrite', overwrite);
    const headers = { 'Content-Type': 'multipart/form-data' };
    try {
      const res = await axios.post('/upload', formData, { headers });
      this.$emit('input', res.data.data.url);
      this.value = res.data.data.url;
      this.uploadLoading = false;
      this.successUpload = true;
      this.failedUpload = false;
    } catch (error) {
      this.failedUploadFile = file;
      this.failedUpload = true;
      this.successUpload = false;
      this.uploadLoading = false;
    }
  }

  limit_characters(text: string, number: number) {
    return text.substring(0, number) + '...';
  }
}
