


























































































































import { Component, Vue } from 'vue-property-decorator';
import vuescroll from 'vuescroll';

@Component({
  components: { vuescroll }
})
export default class Sidebar extends Vue {
  menus = [
    {
      name: 'Posts',
      slug: 'posts',
      icon: 'la la-newspaper',
      is_active: false,
      has_child: false,
      children: []
    },
    {
      name: 'Pages',
      slug: 'pages',
      icon: 'la la-sticky-note',
      is_active: false,
      has_child: false,
      children: []
    },
    // {
    //   name: 'Docume',
    //   slug: 'assets',
    //   icon: 'la la-folder-open',
    //   is_active: false,
    //   has_child: false,
    //   children: []
    // },
    // {
    //   name: 'Iuran Sukarela',
    //   slug: 'iuran',
    //   icon: 'la la-money-bill',
    //   is_active: false,
    //   has_child: true,
    //   children: [
    //     {
    //       name: 'Config',
    //       slug: 'config',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     }
    //   ]
    // },
    // {
    //   name: 'Members',
    //   slug: 'members',
    //   icon: 'la la-user',
    //   is_active: false,
    //   has_child: true,
    //   children: [
    //     {
    //       name: 'Daftar Member',
    //       slug: 'members',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     },
    //     {
    //       name: 'Update Saldo',
    //       slug: 'update-saldo',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     },
    //     {
    //       name: 'Download Data Member',
    //       slug: 'download-data-member',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     },
    //     {
    //       name: 'Update Saldo Detail',
    //       slug: 'update-saldo-detail',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     }
    //   ]
    // },
    // {
    //   name: 'Email',
    //   slug: 'email',
    //   icon: 'la la-envelope',
    //   is_active: false,
    //   has_child: true,
    //   children: [
    //     {
    //       name: 'Broadcast',
    //       slug: 'broadcast',
    //       has_child: false,
    //       is_active: false,
    //       children: []
    //     }
    //   ]
    // },
    {
      name: 'Settings',
      slug: 'settings',
      icon: 'la la-cog',
      is_active: false,
      has_child: true,
      children: [
        {
          name: 'General',
          slug: 'general',
          has_child: false,
          is_active: false,
          children: []
        },
        {
          name: 'Social Media',
          slug: 'social-media',
          has_child: false,
          is_active: false,
          children: []
        }
      ]
    }
  ];

  scrollOptions = {
    rail: {
      background: '#01a99a',
      opacity: 0,
      size: '5px',
      gutterOfEnds: null,
      gutterOfSide: '0px',
      keepShow: false
    },
    bar: {
      background: '#c1c1c1',
      opacity: 1,
      specifyBorderRadius: true,
      size: '8px'
    }
  };

  get currentMenu() {
    const path = this.$route.path.split('/');
    return path[1];
  }

  get currentSubmenu() {
    if (this.currentMenu == 'pages') {
      const pageQuery = this.$route.query.page;
      let selectedPage = '';
      this.menus[1].children.forEach((submenu: any) => {
        if (!submenu.has_child) {
          if (submenu.slug === pageQuery) {
            selectedPage = submenu.slug;
          }
        } else if (submenu.has_child) {
          submenu.children.forEach((submenu2: any) => {
            if (submenu2.slug === pageQuery) {
              selectedPage = submenu.slug;
            }
          });
        }
      });
      return selectedPage;
    } else {
      const path = this.$route.path.split('/');
      return path[2];
    }
  }

  get currentSubmenu2() {
    if (this.currentMenu == 'pages') {
      return this.$route.query.page;
    } else {
      const path = this.$route.path.split('/');
      return path[3];
    }
  }

  async mounted() {
    try {
      const { data } = await this.$store.dispatch('pages/GET_PAGES');
      this.menus[1].children = data;
      this.menus[1].has_child = true;
    } catch (error) {
      return error;
    }
  }

  selectMenu(index: number) {
    const selectedMenu = this.menus[index];
    if (!selectedMenu.has_child) {
      this.menus.forEach((menu: any) => {
        menu.is_active = false;
      });
      selectedMenu.is_active = true;
    } else {
      selectedMenu.is_active = !selectedMenu.is_active;
    }
  }

  selectSubmenu(parent_index: number, index: number) {
    const selectedSubmenu: any = this.menus[parent_index].children[index];
    if (!selectedSubmenu.has_child) {
      this.menus[parent_index].children.forEach((menu: any) => {
        menu.is_active = false;
      });
      selectedSubmenu.is_active = true;
    } else {
      selectedSubmenu.is_active = !selectedSubmenu.is_active;
    }
  }
}
