import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NotFound from '../views/NotFound/pages/NotFound.vue';
import Auth from '../views/Auth/pages/Auth.vue';
import Dashboard from '../views/Dashboard/pages/Dashboard.vue';
import Posts from '../views/Posts/pages/Posts.vue';
import PostsCreate from '../views/Posts/pages/PostsCreate.vue';
import PostsDetail from '../views/Posts/pages/PostsDetail.vue';
import Pages from '../views/Pages/pages/Pages.vue';
import GeneralSettings from '../views/Settings/pages/General.vue';
import PasswordSettings from '../views/Settings/pages/Password.vue';
import SocialMediaSettings from '../views/Settings/pages/SocialMedia.vue';
import IuranConfig from '../views/Iuran/pages/Config.vue';
import store from '../store/index';

// Assets
import Assets from '../views/Assets/pages/Assets.vue';
import AssetsCategory from '../views/Assets/pages/Category.vue';
import AssetsSubcategory from '../views/Assets/pages/Subcategory.vue';

import Products from '../views/Products/Products.vue';
import ProductsDetail from '../views/Products/ProductsDetail.vue';

// Members
import Members from '../views/Members/pages/Members.vue';
import UpdateSaldo from '../views/Members/pages/UpdateSaldo.vue';
import UpdateSaldoDetail from '../views/Members/pages/UpdateSaldoDetail.vue';
import DownloadDataMember from '../views/Members/pages/DownloadDataMember.vue';
import MemberForm from '../views/Members/pages/MemberForm.vue';

Vue.use(VueRouter);

const isAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters['user/LOGIN_STATUS']) {
    return next();
  }
  return next('/');
};

const isNotAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters['user/LOGIN_STATUS']) {
    return next('/posts');
  } else {
    return next();
  }
};

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: isAuthenticated
  },
  {
    path: '/',
    name: 'Auth',
    component: Auth,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: isAuthenticated
  },
  {
    path: '/posts',
    name: 'Posts',
    component: Posts,
    beforeEnter: isAuthenticated
  },
  {
    path: '/posts/create',
    name: 'PostsCreate',
    component: PostsCreate,
    beforeEnter: isAuthenticated
  },
  {
    path: '/posts/get/:id',
    name: 'PostsDetail',
    component: PostsDetail,
    beforeEnter: isAuthenticated
  },
  {
    path: '/pages',
    name: 'Pages',
    component: Pages,
    beforeEnter: isAuthenticated
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets,
    beforeEnter: isAuthenticated
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    beforeEnter: isAuthenticated
  },
  {
    path: '/products-detail',
    name: 'ProductsDetail',
    component: ProductsDetail,
    beforeEnter: isAuthenticated
  },
  {
    path: '/assets/category',
    name: 'AssetsCategory',
    component: AssetsCategory,
    beforeEnter: isAuthenticated
  },
  {
    path: '/assets/category/subcategory/:id',
    name: 'AssetsSubcategory',
    component: AssetsSubcategory,
    beforeEnter: isAuthenticated
  },
  {
    path: '/settings/general',
    name: 'general-settings',
    component: GeneralSettings,
    beforeEnter: isAuthenticated
  },
  {
    path: '/settings/social-media',
    name: 'social-media-settings',
    component: SocialMediaSettings,
    beforeEnter: isAuthenticated
  },
  {
    path: '/settings/password',
    name: 'passwordl-settings',
    component: PasswordSettings,
    beforeEnter: isAuthenticated
  },
  {
    path: '/iuran/config',
    name: 'iuaran-config',
    component: IuranConfig,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/members',
    name: 'members',
    component: Members,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/add',
    name: 'members',
    component: MemberForm,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/update/:id',
    name: 'members-update',
    component: MemberForm,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/update-saldo',
    name: 'update-saldo',
    component: UpdateSaldo,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/download-data-member',
    name: 'download-data-member',
    component: DownloadDataMember,
    beforeEnter: isAuthenticated
  },
  {
    path: '/members/update-saldo-detail',
    name: 'update-saldo-detail',
    component: UpdateSaldoDetail,
    beforeEnter: isAuthenticated
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
