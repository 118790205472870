var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-upload","title":"Upload File","hide-footer":""},on:{"shown":_vm.onModalShown,"hide":_vm.resetData}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"refs":"uploadForm","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v("Select category")]),_c('SelectData',{attrs:{"loading":_vm.loading_category,"data":_vm.categories,"blank":true,"required":"","failed":_vm.failed_category},on:{"select_category":_vm.selectCategory,"retry":_vm.retryCategories},model:{value:(_vm.dataToPost.category_id),callback:function ($$v) {_vm.$set(_vm.dataToPost, "category_id", $$v)},expression:"dataToPost.category_id"}})],1)]),(_vm.hasSubcategory)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v("Subcategory")]),_c('SelectData',{attrs:{"loading":_vm.loading_subcategory,"data":_vm.subcategories,"required":"","blank":false,"failed":_vm.failed_subcategory},on:{"retry":_vm.selectCategory},model:{value:(_vm.dataToPost.subcategory_id),callback:function ($$v) {_vm.$set(_vm.dataToPost, "subcategory_id", $$v)},expression:"dataToPost.subcategory_id"}})],1)]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"Visibility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v("Visibility")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataToPost.visibility),expression:"dataToPost.visibility"}],staticClass:"form-control form-control-solid",class:classes,attrs:{"disabled":_vm.loading,"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataToPost, "visibility", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","value":"Public"}},[_vm._v("Public")]),_c('option',{attrs:{"value":"Private"}},[_vm._v("Private")]),_c('option',{attrs:{"value":"Hidden"}},[_vm._v("Hidden")])]),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"File name (ID)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v(" File name "),(_vm.main_config.translation)?_c('span',[_vm._v("(ID)")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataToPost.name),expression:"dataToPost.name"}],staticClass:"form-control form-control-solid",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.dataToPost.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataToPost, "name", $event.target.value)}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v(" Upload file "),(_vm.main_config.translation)?_c('span',[_vm._v("(ID)")]):_vm._e()]),_c('UploadInput',{attrs:{"directory":"assets","name":"File (ID)","encrypt":true,"overwrite":false,"disabled":_vm.loading,"required":""},model:{value:(_vm.dataToPost.file),callback:function ($$v) {_vm.$set(_vm.dataToPost, "file", $$v)},expression:"dataToPost.file"}})],1)]),(_vm.main_config.translation)?_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"File name (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v("File name (EN)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataToPost.name_en),expression:"dataToPost.name_en"}],staticClass:"form-control form-control-solid",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.dataToPost.name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataToPost, "name_en", $event.target.value)}}}),_c('span',{staticClass:"form-error-message"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1):_vm._e(),(_vm.main_config.translation)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder",attrs:{"for":""}},[_vm._v("Upload file (EN)")]),_c('UploadInput',{attrs:{"name":"File (EN)","directory":"assets","encrypt":true,"overwrite":false,"disabled":_vm.loading,"required":""},model:{value:(_vm.dataToPost.file_en),callback:function ($$v) {_vm.$set(_vm.dataToPost, "file_en", $$v)},expression:"dataToPost.file_en"}})],1)]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-success btn-sm font-weight-bolder",attrs:{"type":"submit","disabled":_vm.loading || _vm.loading_category}},[(!_vm.loading)?_c('div',[_c('i',{staticClass:"la la-save"}),_vm._v(" Submit ")]):_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"spinner spinner-track spinner-success spinner-sm mr-6"}),_c('span',{staticClass:"ml-2"},[_vm._v("Loading...")])])])])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }