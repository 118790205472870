

































































import { Component, Vue } from 'vue-property-decorator';
import '@/assets/sass/pages/auth/auth.scss';
import Signin from '../components/Signin.vue';
import Signup from '../components/Signup.vue';
import ForgotPassword from '../components/ForgotPassword.vue';

@Component({
  components: {
    Signin,
    Signup,
    ForgotPassword
  }
})
export default class Auth extends Vue {
  currentPage = 'signin';

  changePage(page: string): void {
    this.currentPage = page;
  }
}
