




















































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import UploadInput from '@/components/UploadInput/UploadInput.vue';
import SelectData from '@/components/SelectData.vue';
import MainConfig from '@/config/config';
import axios from 'axios';
import AddModal from './AddModal.vue';
import UpdateModal from './UpdateModal.vue';
@Component({
  components: { UploadInput, SelectData, AddModal, UpdateModal },
  computed: {}
})
export default class Cards extends Vue {
  @Prop(Object) selected_section: any;
  mainconfig = MainConfig;
  loading_get_cards = false;
  loading_process = false;
  cards = [];
  selected_card = '';

  @Watch('selected_section', { immediate: false, deep: false })
  async onSelectedSectionChanged() {
    this.getCards();
  }

  mounted() {
    this.getCards();
  }

  async getCards() {
    this.loading_get_cards = true;
    const { data } = await axios.get(
      '/cards/get?section_id=' + this.selected_section.id
    );
    this.cards = data.data;
    this.loading_get_cards = false;
  }

  deleteCard(id: string) {
    this.$swal({
      title: 'Are you sure want to delete this card?',
      text: 'Your action could not be reverted',
      showCancelButton: true,
      icon: 'info',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#03BBB2',
      denyButtonText: `Cancel`
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_get_cards = true;
        await axios.delete('/cards/delete/' + id);
        this.getCards();
      }
    });
  }

  async decreaseOrder(id: string) {
    this.loading_get_cards = true;
    await axios.put('/cards/increase-order/' + id);
    await this.getCards();
  }

  async increaseOrder(id: string) {
    this.loading_get_cards = true;
    await axios.put('/cards/decrease-order/' + id);
    await this.getCards();
  }
}
