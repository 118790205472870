


























































































































































































































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import CategoryModal from '../components/CategoryModal.vue';

import Editor from '@tinymce/tinymce-vue';

interface DataToPostType {
  title: string;
  content: string;
  tags: string[];
  image: string;
  category_id: string;
  visibility: string;
  status: string;
}

@Component({
  components: { Editor, CategoryModal },
  computed: {}
})
export default class PostsCreate extends Vue {
  get categories() {
    return this.$store.getters['posts/CATEGORIES'];
  }

  modules = {
    table: true
  };

  tagInput = '';
  uploadLoading = false;
  categoryLoading = true;
  savePostLoading = false;
  dataToPost: DataToPostType = {
    title: '',
    content: '',
    tags: [],
    image: '',
    category_id: '',
    visibility: 'Public',
    status: 'Published'
  };

  addTags() {
    if (this.tagInput) {
      this.dataToPost.tags.push(this.tagInput);
      this.tagInput = '';
    }
  }
  removeTag(index: number) {
    this.dataToPost.tags.splice(index, 1);
  }

  triggerUpload() {
    const elem: HTMLElement = this.$refs.imageInput as HTMLElement;
    elem.click();
  }

  removeImage() {
    this.dataToPost.image = '';
    const elm: any = this.$refs.imageInput as HTMLElement;
    elm.value = '';
  }

  uploadImage(event: any) {
    this.uploadLoading = true;
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append('file', image);
    formData.append('directory', 'posts/');
    const headers = { 'Content-Type': 'multipart/form-data' };
    axios
      .post('/upload', formData, { headers })
      .then((res) => {
        this.dataToPost.image = res.data.data.url;
      })
      .finally(() => {
        this.uploadLoading = false;
      });
  }

  async mounted() {
    try {
      await this.$store.dispatch('posts/GET_POSTS_CATEGORY');
      this.categoryLoading = false;
    } catch (error) {
      return error;
    }
  }

  async savePost() {
    this.savePostLoading = true;
    try {
      const { data } = await this.$store.dispatch(
        'posts/CREATE_NEW_POST',
        this.dataToPost
      );
      this.savePostLoading = false;
      this.$router.push({ path: '/posts/get/' + data });
    } catch (error) {
      return error;
    }
  }
}
