





































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import SectionInformation from '@/views/Pages/components/SectionInformation/SectionInformation.vue';
import SectionItem from '../components/SectionItem/SectionItem.vue';
import SectionList from '../components/SectionList.vue';
import axios from 'axios';

import Loading from '../../../components/Loading.vue';
@Component({
  components: { SectionInformation, SectionList, SectionItem, Loading },
  computed: {}
})
export default class Pages extends Vue {
  sections = [];
  sections_failed = false;
  sections_loading = true;
  selected_section_id = '';
  page = null;
  loading_page = true;
  loading_update = false;

  get selected_page() {
    return this.$route.query.page;
  }

  @Watch('selected_page', { immediate: false, deep: false })
  async onSelectedPageChanged() {
    this.selected_section_id = '';
    this.get_sections();
    this.get_menu();
  }

  mounted() {
    this.get_sections();
    this.get_menu();
  }

  async get_menu() {
    this.loading_page = true;
    const { data } = await axios.get('/page/get?page=' + this.selected_page);
    this.page = data.data[0];
    this.loading_page = false;
  }

  async get_sections() {
    try {
      this.sections_loading = true;
      this.sections_failed = false;
      const { data } = await axios.get(
        '/sections/get?page=' + this.selected_page
      );
      this.sections = data.data;
      this.selected_section_id = data.data[0].id;
      this.sections_loading = false;
    } catch (error) {
      this.sections_loading = false;
      this.sections_failed = true;
    }
  }

  async submitPage() {
    this.loading_update = true;
    await axios.put('/page/update', this.page);
    this.loading_update = false;
  }

  select_section(id: string) {
    this.selected_section_id = id;
  }
}
