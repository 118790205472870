var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('vuescroll',{attrs:{"ops":_vm.scrollOptions}},[_c('div',{staticClass:"sidebar__brand flex-column"},[_c('img',{staticClass:"img-fluid",staticStyle:{"height":"50px"},attrs:{"src":"/media/logo/logo-white.png","alt":""}})]),_c('ul',{staticClass:"sidebar__menu"},_vm._l((_vm.menus),function(menu,menu_idx){return _c('li',{key:menu.slug,staticClass:"sidebar__menu__item"},[(!menu.has_child)?_c('router-link',{class:("sidebar__menu__link " + (_vm.currentMenu === menu.slug &&
              'sidebar__menu__link--active')),attrs:{"to":'/' + menu.slug},on:{"click":function($event){return _vm.selectMenu(menu_idx)}}},[_c('i',{class:menu.icon}),_c('span',{staticClass:"sidebar__menu__link__text"},[_vm._v(_vm._s(menu.name))])]):_c('a',{class:("sidebar__menu__link cursor-pointer " + (_vm.currentMenu === menu.slug &&
              'sidebar__menu__link--active')),on:{"click":function($event){return _vm.selectMenu(menu_idx)}}},[_c('i',{class:menu.icon}),_c('span',{staticClass:"sidebar__menu__link__text"},[_vm._v(_vm._s(menu.name))]),(menu.is_active)?_c('i',{staticClass:"fa fa-chevron-down ml-auto icon-md"}):_c('i',{staticClass:"fa fa-chevron-right ml-auto icon-md"})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(menu.is_active),expression:"menu.is_active"}],staticClass:"sidebar__menu"},_vm._l((menu.children),function(submenu,submenu_idx){return _c('li',{key:submenu.id,staticClass:"sidebar__menu__item"},[(!submenu.has_child)?_c('router-link',{class:("sidebar__menu__link sidebar__menu__link--submenu pl-10 cursor-pointer " + (_vm.currentSubmenu ===
                  submenu.slug && 'sidebar__menu__link--active')),attrs:{"to":menu.slug !== 'pages'
                  ? ("/" + (menu.slug) + "/" + (submenu.slug))
                  : ("/" + (menu.slug) + "?page=" + (submenu.slug))},on:{"click":function($event){return _vm.selectSubmenu(menu_idx, submenu_idx)}}},[_c('i',{staticClass:"fa fa-genderless icon-md"}),_c('span',{staticClass:"sidebar__menu__link__text"},[_vm._v(" "+_vm._s(submenu.name)+" ")]),(submenu.has_child)?_c('i',{staticClass:"fa fa-chevron-down ml-auto icon-md"}):_vm._e()]):_c('a',{class:("sidebar__menu__link sidebar__menu__link--submenu pl-10 cursor-pointer " + (_vm.currentSubmenu ===
                  submenu.slug && 'sidebar__menu__link--active')),on:{"click":function($event){return _vm.selectSubmenu(menu_idx, submenu_idx)}}},[_c('i',{staticClass:"fa fa-genderless icon-md"}),_c('span',{staticClass:"sidebar__menu__link__text"},[_vm._v(" "+_vm._s(submenu.name)+" ")]),(submenu.has_child)?_c('i',{staticClass:"fa fa-chevron-down ml-auto icon-md"}):_vm._e()]),(submenu.has_child && submenu.is_active)?_c('ul',{staticClass:"sidebar__menu"},[_c('li',{staticClass:"sidebar__menu__item"},_vm._l((submenu.children),function(submenu2){return _c('router-link',{key:submenu2.id,class:("sidebar__menu__link sidebar__menu__link--submenu pl-16 border-0 cursor-pointer " + (_vm.currentSubmenu2 ===
                      submenu2.slug && 'sidebar__menu__link--active')),attrs:{"to":("/" + (menu.slug) + "?page=" + (submenu2.slug))}},[_c('i',{staticClass:"fa fa-genderless icon-md"}),_c('span',{staticClass:"sidebar__menu__link__text"},[_vm._v(" "+_vm._s(submenu2.name)+" ")])])}),1)]):_vm._e()],1)}),0)],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }